import type { Board, NodeUserContext } from '@aurora/shared-generated/types/graphql-schema-types';
import { RegistrationStatus } from '@aurora/shared-generated/types/graphql-schema-types';
import { NodeType } from '@aurora/shared-types/nodes/enums';
import { TextAlignment } from '@aurora/shared-types/texts/enums';
import { checkPolicy } from '@aurora/shared-utils/helpers/objects/PolicyResultHelper';
import { NodeHeaderContentWidth, NodeHeaderLayout, NodeHeaderVariant } from '../../../types/enums';
import type {
  ContextNodeFragment,
  GroupHubDescendantsFragment,
  MembershipInformationFragment
} from '../../../types/graphql-types';
import type { NodeHeaderVariantProps } from '../../nodes/NodeHeader/NodeHeader';
import ConversationStyleBehaviorHelper from '../../../helpers/boards/ConversationStyleBehaviorHelper';

/**
 * Helper function used to determine whether the NodeHeader action button and title props should be used
 *
 * @param node the context node
 * @param groupHubInformation
 * @param registrationStatus Current Registration Status of the user
 * @param confirmEmailStatus the confirmation email status of the user
 * @param userContext the node user context
 */
export function isNodeAbleToDisplayActionButton(
  node: ContextNodeFragment,
  userContext: NodeUserContext,
  registrationStatus: RegistrationStatus,
  confirmEmailStatus: boolean,
  groupHubInformation?: MembershipInformationFragment & GroupHubDescendantsFragment
): boolean {
  if (node?.nodeType === NodeType.BOARD) {
    const { isAnonymousPostingSupported, isUnverifiedEmailPostingSupporting } =
      ConversationStyleBehaviorHelper.getInstance(node as Board);

    if (registrationStatus === RegistrationStatus.Anonymous && isAnonymousPostingSupported) {
      return true;
    } else {
      /**
       * 1. If it's forum and email status not verified, show the button
       * 2. Check if user can post message and has confirmed email status for
       *    creating all discussion styles
       */
      if (!confirmEmailStatus && isUnverifiedEmailPostingSupporting) {
        return true;
      } else {
        return userContext?.canPostMessages && confirmEmailStatus;
      }
    }
  }

  if (node?.nodeType === NodeType.GROUPHUB) {
    const isMember = groupHubInformation?.isMember;
    const { canUpdateNode, canPostMessages } = userContext;
    const canJoin = checkPolicy(groupHubInformation?.membershipPolicies.canJoin);
    if (canJoin) {
      return true;
    } else {
      const { edges } = groupHubInformation?.descendants || {};
      const forum = edges?.find(edge => edge.node.__typename === 'Forum')?.node;
      if (forum) {
        return (isMember || canUpdateNode) && canPostMessages;
      } else {
        return false;
      }
    }
  }

  return false;
}

/**
 * Helper function used to determine the default action button text key for the NodeHeader
 *
 * @param node the context node
 * @param groupHubInformation
 * @param userContext the node user context
 * @param registrationStatus Current Registration status of the user
 * @param confirmEmailStatus the confirmation email status of the user
 */
export function getDefaultActionButtonTextKey(
  node: ContextNodeFragment,
  userContext,
  registrationStatus: RegistrationStatus,
  confirmEmailStatus: boolean,
  groupHubInformation?: MembershipInformationFragment & GroupHubDescendantsFragment
): string {
  if (
    node?.nodeType === NodeType.BOARD &&
    isNodeAbleToDisplayActionButton(
      node,
      userContext,
      registrationStatus,
      confirmEmailStatus,
      groupHubInformation
    )
  ) {
    return 'actionButton.title';
  } else if (node?.nodeType === NodeType.GROUPHUB) {
    const isMember = groupHubInformation?.isMember;
    const { canUpdateNode } = userContext;
    if (checkPolicy(groupHubInformation?.membershipPolicies.canJoin)) {
      return 'joinGroupHub';
    } else if (isMember || canUpdateNode) {
      return 'actionButton.grouphub.startDiscussion';
    }
  }

  return 'defaultActionButtonTitle';
}

/**
 * Helper function used to create a default props object for the "large" type NodeHeaderVariantProps
 *
 * @param isCommunity whether or not the context node is a community node
 * @param useActionButtonProps whether or not to use the NodeHeader action button props
 */
export function getDefaultLargeNodeHeaderVariantProps(
  isCommunity: boolean,
  useActionButtonProps: boolean
): NodeHeaderVariantProps {
  return {
    type: NodeHeaderVariant.LG,
    alignment: TextAlignment.CENTER,
    contentWidth: NodeHeaderContentWidth.MEDIUM,
    useSearch: isCommunity,
    isSearchGlobal: isCommunity,
    ...(useActionButtonProps && { useActionButton: true })
  };
}

/**
 * Helper function used to create a default props object for the "medium" type NodeHeaderVariantProps
 *
 * @param isCommunity whether or not the context node is a community node
 * @param useActionButtonProps whether or not to use the NodeHeader action button props
 */
export function getDefaultMediumNodeHeaderVariantProps(
  isCommunity: boolean,
  useActionButtonProps: boolean
): NodeHeaderVariantProps {
  return {
    type: NodeHeaderVariant.MD,
    layout: isCommunity ? NodeHeaderLayout.EQUAL_WIDTH : NodeHeaderLayout.WIDE_LEFT,
    useAvatar: !isCommunity,
    useSearch: isCommunity,
    isSearchGlobal: isCommunity,
    ...(useActionButtonProps && { useActionButton: true })
  };
}

/**
 * Helper function used to create a default props object for the "small" type NodeHeaderVariantProps
 */
export function getDefaultSmallNodeHeaderVariantProps(): NodeHeaderVariantProps {
  return {
    type: NodeHeaderVariant.SM,
    alignment: TextAlignment.CENTER,
    contentWidth: NodeHeaderContentWidth.MEDIUM
  };
}

/**
 * Helper function used to retrieve the defaults for the NodeHeaderVariantProps prop. Updates incoming props from the
 * props interface (nodeHeaderVariantProps) with any missing attributes, which is helpful during configuration in
 * PageEditor
 *
 * @param isCommunity whether or not the context node is a community node
 * @param nodeHeaderVariantProps props coming directly from the props interface for the NodePageHeaderWidget component
 * @param defaultLargeNodeHeaderVariantProps default props object for the "large" type NodeHeaderVariantProps
 * @param defaultMediumNodeHeaderVariantProps default props object for the "medium" type NodeHeaderVariantProps
 * @param defaultSmallNodeHeaderVariantProps default props object for the "small" type NodeHeaderVariantProps
 */
export function getDefaultNodeHeaderVariantProps(
  isCommunity: boolean,
  nodeHeaderVariantProps: NodeHeaderVariantProps,
  defaultLargeNodeHeaderVariantProps: NodeHeaderVariantProps,
  defaultMediumNodeHeaderVariantProps: NodeHeaderVariantProps,
  defaultSmallNodeHeaderVariantProps: NodeHeaderVariantProps
): NodeHeaderVariantProps {
  let defaultNodeHeaderVariantProps: NodeHeaderVariantProps;
  const { type } = nodeHeaderVariantProps || {};
  if (!type) {
    defaultNodeHeaderVariantProps = isCommunity
      ? defaultLargeNodeHeaderVariantProps
      : defaultMediumNodeHeaderVariantProps;
  } else {
    switch (type) {
      case NodeHeaderVariant.LG: {
        defaultNodeHeaderVariantProps = defaultLargeNodeHeaderVariantProps;
        break;
      }
      case NodeHeaderVariant.MD: {
        defaultNodeHeaderVariantProps = defaultMediumNodeHeaderVariantProps;
        break;
      }
      default: {
        defaultNodeHeaderVariantProps = defaultSmallNodeHeaderVariantProps;
      }
    }
  }
  return defaultNodeHeaderVariantProps;
}
