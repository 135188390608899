import AppContext from '@aurora/shared-client/components/context/AppContext/AppContext';
import useNodeLocalizedCategoriesProperties from '@aurora/shared-client/components/nodes/NodeProperties/useNodeLocalizedCategoriesProperties';
import useRegistrationStatus from '@aurora/shared-client/components/users/useRegistrationStatus';
import { NodeType } from '@aurora/shared-types/nodes/enums';
import { EndUserComponent } from '@aurora/shared-types/pages/enums';
import { useContext } from 'react';
import type { GroupHubDetailsQueryVariables } from '../../../types/graphql-types';
import useGroupHubDetails from '../../grouphubs/useGroupHubDetails';
import useTranslation from '../../useTranslation';
import { getDefaultActionButtonTextKey } from './NodeBannerWidgetHelper';

export interface NodePageHeaderTextReturn {
  /**
   * true when text is loading
   */
  loading: boolean;
  /**
   * title of the node page header
   */
  title: string;
  /**
   * description of the node page header
   */
  description: string;
  /**
   * title of the action button
   */
  actionButtonTitle: string;
}

/**
 * Returns title and description for page header
 * @author Manish Shrestha
 */
export function useNodePageHeaderText(skip = false): NodePageHeaderTextReturn {
  const { contextNode } = useContext(AppContext);
  const { userContext } = contextNode;
  const isCommunity = contextNode.nodeType === NodeType.COMMUNITY;
  const isGroupHub = contextNode.nodeType === NodeType.GROUPHUB;

  const { formatMessage, loading: textLoading } = useTranslation(
    EndUserComponent.NODE_PAGE_HEADER_WIDGET,
    skip
  );
  const { data, loading: localizedCategoriesPropertiesLoading } =
    useNodeLocalizedCategoriesProperties(module, null, null, true, skip);

  const variables: GroupHubDetailsQueryVariables = {
    id: contextNode.id,
    useGroupHubDescendants: true,
    useMembershipInformation: true
  };

  const {
    queryResult: { data: groupHubData },
    loading: groupHubInfoLoading
  } = useGroupHubDetails(variables, !isGroupHub);

  const { registrationStatus, confirmEmailStatus } = useRegistrationStatus();

  const { title: contextNodeTitle, description: contextNodeDescription } = contextNode;
  const { localizedCategoriesBannerTitle, localizedCategoriesBannerDescription } =
    data?.category.localizedCategoriesProperties ?? {};

  const formattedTitleForPage: string = formatMessage(isCommunity ? 'title.home' : 'title', {
    title:
      localizedCategoriesBannerTitle && localizedCategoriesBannerTitle !== ''
        ? localizedCategoriesBannerTitle
        : contextNodeTitle
  });
  const formattedDescriptionForPage: string = formatMessage(
    isCommunity ? 'description.home' : 'description',
    {
      description:
        localizedCategoriesBannerDescription && localizedCategoriesBannerDescription !== ''
          ? localizedCategoriesBannerDescription
          : contextNodeDescription
    }
  );
  const formattedActionButtonTitleForPage: string = formatMessage(
    getDefaultActionButtonTextKey(
      contextNode,
      userContext,
      registrationStatus,
      confirmEmailStatus,
      groupHubData?.groupHub
    )
  );

  return {
    loading: textLoading || localizedCategoriesPropertiesLoading || groupHubInfoLoading,
    title: formattedTitleForPage,
    description: formattedDescriptionForPage,
    actionButtonTitle: formattedActionButtonTitleForPage
  };
}
