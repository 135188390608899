import type { QueryOptions, QueryResult } from '@apollo/client';
import { getLog } from '@aurora/shared-utils/log';
import { useContext } from 'react';
import type {
  NodeLocalizedCategoriesPropertiesQuery,
  NodeLocalizedCategoriesPropertiesQueryVariables
} from '@aurora/shared-generated/types/graphql-types';
import type { Optional } from '@aurora/shared-types/community';
import { isNodeCategory } from '../../../helpers/nodes/NodeUtils';
import AppContext from '../../context/AppContext/AppContext';
import useQueryWithTracing from '../../useQueryWithTracing';
import nodePropertiesQuery from './NodeLocalizedCategoriesProperties.query.graphql';

const log = getLog(module);

/**
 * Gets category-specific properties for a node.
 *
 * @param module The module
 * @param variables any node property query variables.
 * @param options optional query options
 * @param useCache whether to use the request scoped cached value if one exists.
 * @param skip
 * @author Luisina Santos
 */
export default function useNodeLocalizedCategoriesProperties(
  module: NodeModule | string,
  variables?: Optional<NodeLocalizedCategoriesPropertiesQueryVariables, 'id'>,
  options?: QueryOptions,
  useCache = true,
  skip = false
): QueryResult<
  NodeLocalizedCategoriesPropertiesQuery,
  NodeLocalizedCategoriesPropertiesQueryVariables
> {
  const { contextNode } = useContext(AppContext);
  const modifiedVariables = { id: contextNode.id, ...variables };
  const skipQuery = skip || !isNodeCategory(contextNode);
  const result = useQueryWithTracing<
    NodeLocalizedCategoriesPropertiesQuery,
    NodeLocalizedCategoriesPropertiesQueryVariables
  >(module, nodePropertiesQuery, {
    ...(!useCache && { fetchPolicy: 'network-only' }),
    ...options,
    variables: modifiedVariables,
    skip: skipQuery
  });
  if (skipQuery) {
    log.debug('Skipping localized categories properties query for node %O', contextNode.id);
  }

  if (result?.error) {
    log.error(
      result.error,
      'Error getting node localized categories properties for %O',
      modifiedVariables
    );
  }
  return result;
}
